<!--
 * @Author: pengyu
 * @Date: 2020-07-15 15:32:31
 * @LastEditors: linshanfeng
 * @LastEditTime: 2024-02-06 17:50:28
 * @Description: 地址控件
-->
<template>
  <div class="address-container">
    <div class="address-select" v-if="column.editable">
      <el-input type="text" v-if="isAutoPosition" disabled :value="fullAddress || '请使用手机端定位获取地址'"/>
      <select-address ref="selectAddress" clearable @openAmap="openAmap"
        :disabled="isManualSelect"
        v-if="!isAutoPosition" v-model="column.columnValue" :column="column"></select-address>
      <!-- 详细地址 -->
      <el-popover
        v-if="Number(column.addrRange) < 2 && !isAutoPosition"
        placement="bottom-start"
        v-model="popoverVisible"
        :arrow-offset="30"
        trigger="click"
        :width="500"
        class="detail-input"
      >
        <div class="en-address-item-main" >
          <div
            v-for="(item, index) in detailList"
            :key="item.id"
            :class="{'active-address-item': index === activeIndex}"
            class="en-address-item"
            @click="choiceAddress(item)"
          >
            <div class="en-address-basic">
              <div class="en-address-name">
                {{ item.name }}
              </div>
              <div class="en-address-details">
                {{ item.detail }}
              </div>
            </div>
            <div class="en-address-city-info">
              {{ item.areaName }}
            </div>
          </div>
        </div>
        <div slot="reference">
          <el-input
            v-model="column.columnValue.address"
            placeholder="输入详细地址"
            :disabled="!column.editable || isManualSelect"
            @keyup.prevent.native="handleKeyup"
            @click.native="handleInputClick"
            @input="handleAddressChange"
          >
          <template slot="prepend">
            <en-icon
            slot="append"
            name="icondituchaxun-dizhikongjian"
            size="small"
            title="地图"
            color="#3e90fe"
            style="cursor: pointer;"
            @click.native="openAmap"
          ></en-icon>
          </template>
          </el-input>
        </div>
      </el-popover>
      <RightAddition v-if="rightAdditionButtons.length"
                  :column="column"
                  :business-data="businessData"
                  @geoSuccess="geoSuccess">
      </RightAddition>
    </div>
    <div class="address-view" v-else>
      <el-input
        type="text"
        :value="fullAddress"
        disabled
        style="width:100%;"
      >
        <!-- 用车 -->
        <en-icon
          slot="append"
          v-if="userCarAbled"
          name="bus-board-icon2"
          color="#189edb"
          size="small"
          @click.native="toCarPage"
        ></en-icon>
      </el-input>
    </div>
    <amap-dialog :visible="amapVisible" @success="geoSuccess" @close="amapVisible = false"></amap-dialog>
  </div>
</template>

<script>
import cityData from "cityData";
import { debounce } from "lodash";
import AmapDialog from "./AmapDialog";
import MxColumnData from "../MxColumnData.js";
import { AssignmentOptions, BusinessDataOpenType } from "../../../data/BusinessCommonHeader.js";
import RightAddition from "../components/rightAddition";
import MxRightAddition from "../components/rightAddition/MxRightAddition";
import SelectAddress from "./SelectAddress";
// import { btnService } from "@/api/businessModel";
// showType 1手动输入 2自动定位 3手动选择
export default {
  name: "BMAddressColumn",
  mixins: [MxColumnData, MxRightAddition],
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    RightAddition,
    SelectAddress,
    AmapDialog
  },
  watch: {
  },
  mounted() {
  },
  computed: {
    userCarAbled() {
      const { provinceId, cityId, districtId } = this.column.columnValue;
      return this.hasUserCarMenu && provinceId && cityId && districtId && this.businessData.openType === BusinessDataOpenType.view;
    },
    hasUserCarMenu() {
      const menuList = this.$store.getters["main/menuList"];
      return menuList.some((item) => item.dataList?.some((data) => data.code === "023"));
    },
    provinceList() { // 省会数据
      return this.formatData(cityData);
    },
    fullAddress() {
      const { columnValue } = this.column;
      return `${columnValue.provinceName || ""}${columnValue.cityName || ""}${columnValue.districtName || ""}${columnValue.address || ""}`;
    },
    // 是否自动定位
    isAutoPosition() {
      console.log(this.column.showType, "this.column.showType");
      return Number(this.column.showType) === 2;
    },
    // 手动选择
    isManualSelect() {
      return Number(this.column.showType) === 3;
    }
  },
  data() {
    return {
      detailList: [],
      cityList: [], // 城市
      districtList: [], // 区域
      amapVisible: false,
      popoverVisible: false,
      activeIndex: -1
    };
  },
  methods: {
    async toCarPage() {
      // const res = await btnService.checkCarAuth();
      // console.log(res);
      this.$router.push("/business-travel/car");
    },
    handleKeyup(e) {
      e.preventDefault();
      const { length } = this.detailList;
      const scrollIntoView = () => {
        const activeDom = document.querySelector(".active-address-item");
        activeDom?.scrollIntoView();
      };
      if (this.popoverVisible) {
        switch (e.keyCode) {
          // 箭头上
          case 38:
            this.activeIndex = this.activeIndex === 0 || this.activeIndex === -1 ? length - 1 : this.activeIndex - 1;
            break;
          // 箭头下
          case 40:
            this.activeIndex = this.activeIndex >= length - 1 || this.activeIndex === -1 ? 0 : this.activeIndex + 1;
            break;
          // 回车键
          case 13:
            this.choiceAddress(this.detailList[this.activeIndex]);
            break;
        }
        this.$nextTick(() => {
          scrollIntoView();
        });
      }
    },
    handleInputClick() {
      setTimeout(() => {
        if (!this.detailList.length) {
          this.popoverVisible = false;
        }
      });
    },
    confirmSelectAddress({ addressComponent }) {
      console.log(addressComponent);
      this.amapVisible = false;
    },
    /**
     * @description: 初始化数据
     * @param list
     * @return: arr
     */
    formatData(list = []) {
      const arr = [];
      list.forEach((itm) => {
        const citm = JSON.parse(JSON.stringify(itm));
        citm.id = citm.ec;
        citm.text = citm.en;
        citm.hasChildren = "001";
        citm.selectable = true;
        arr.push(citm);
      });
      return arr;
    },
    openAmap() {
      this.amapVisible = true;
    },
    /**
     * @description: 更新showValue的值
     * @param value 值
     */
    // addrRange 省==4 省市==3 省市区==2 省市区详细地址==1
    updateShowValue(value) {
      const addrRange = Number(this.column.addrRange);
      let formValue = {};
      if ([4, 3, 2].includes(addrRange)) {
        formValue.provinceId = value.provinceId;
        formValue.provinceName = value.provinceName;
      }
      if ([3, 2].includes(addrRange)) {
        formValue.cityId = value.cityId;
        formValue.cityName = value.cityName;
      }
      if ([2].includes(addrRange)) {
        formValue.districtId = value.districtId;
        formValue.districtName = value.districtName;
      }
      if (addrRange === 1) {
        formValue = value;
      }
      this.column.columnValue = { ...this.column.columnValue, ...formValue };
      this.column.updateValueWithOptions(this.column.columnValue, AssignmentOptions.manual);
      this.$nextTick(() => {
        this.$refs.selectAddress.fillback();
      });
    },
     /**
     * @description: 输入详细地址
     * @param value  详细地址
     */
    handleAddressChange(value) {
      this.initPlaceSearch(value, this, (list) => {
        this.detailList = list;
        this.popoverVisible = list.length > 0;
      });

      this.updateShowValue({ lng: "", lat: "" });
    },
    /**
     * @description: 选中详细地址
     * @param data  地址信息
     */
    choiceAddress(data) {
      const {
        pname, cityname, adname, address
      } = data;
      const lat = data.lat || data.location.lat;
      const lng = data.lng || data.location.lng;
      const prov = this.provinceList.filter((itm) => itm.en === pname);
      if (prov[0]) {
        this.cityList = this.formatData(prov[0].ns);
        const city = this.cityList.filter((itm) => itm.en === cityname);
        if (city[0]) {
          this.districtList = this.formatData(city[0].ns);
          const dist = this.districtList.filter((itm) => itm.en === adname);
          if (dist[0]) {
            this.updateShowValue({
              provinceId: prov[0].id,
              provinceName: prov[0].text,
              cityId: city[0].id,
              cityName: city[0].text,
              districtId: dist[0].id,
              districtName: dist[0].text,
              address,
              lat,
              lng
            });
          } else {
            this.updateShowValue({
              provinceId: prov[0].id,
              provinceName: prov[0].text,
              cityId: city[0].id,
              cityName: city[0].text,
              address,
              lat,
              lng
            });
          }
        } else {
          this.updateShowValue({
            provinceId: prov[0].id,
            provinceName: prov[0].text,
            address,
            lat,
            lng
          });
        }
      }
      // 选择搜索地址 需要回填省市区
      // this.detailList = [];
      this.popoverVisible = false;
    },
    geoSuccess(pois) {
      const { addressComponent, formattedAddress, position } = pois;
      const address = formattedAddress.split(addressComponent.district)[1];
      const data = {
        ...position,
        pname: addressComponent.province,
        cityname: addressComponent.city,
        adname: addressComponent.district,
        address
      };
      this.amapVisible = false;
      this.choiceAddress(data);
    },
    /**
     * @description: 初始化详细地址列表
     * @param keywords  关键字
     * @param resolve  回调
     */
    initPlaceSearch: debounce((keywords, that, resolve) => {
      // eslint-disable-next-line no-undef
      const map = AMap;
      map.plugin(["AMap.Autocomplete", "AMap.PlaceSearch"], () => {
        console.log("that.column?.columnValue?.cityName ", that.column?.columnValue?.cityName);
        const rangeCity = that.column?.columnValue?.cityName || "";
        const autocomplete = new map.Autocomplete({ datatype: "poi", city: rangeCity });
        autocomplete.search(keywords, (status, result) => {
          if (status === "no_data" || status === "error") {
            resolve([]);
          } else {
            const data = [];
            const placeSearch = new map.PlaceSearch({ extensions: "all" });
            const all = result.tips.map((item) => new Promise((resolve) => {
              placeSearch.getDetails(item.id, (status, result) => {
                console.log(result);
                if (status === "no_data" || status === "error") {
                  item.detail = item.address;
                  item.areaName = "中国";
                } else if (result?.poiList?.pois?.length > 0) {
                  const detailData = result?.poiList?.pois[0];
                  const detailCity = detailData.cityname.replace("市", "");
                  item.pcode = detailData.pcode;
                  item.pname = detailData.pname;
                  item.cityname = detailData.cityname;
                  item.citycode = detailData.citycode;
                  item.adcode = detailData.adcode;
                  item.adname = detailData.adname;
                  item.address = detailData.address;
                  item.detail = `${item.cityname}-${item.adname}${item.address.length > 0 ? `-${item.address}` : ""}`;
                  item.areaName = `${detailCity}，中国`;
                  console.log(rangeCity, detailCity, rangeCity.indexOf(detailCity));
                  if (!rangeCity || rangeCity.indexOf(detailCity) > -1) {
                    data.push(item);
                  }
                }
                resolve();
              });
            }));
            Promise.all(all).then(() => {
              resolve(data);
            });
          }
        });
      });
      }, 500)
    }
};
</script>

<style lang='scss' scoped>
.address-container{
  width: 100%;
  .address-select{
    width: inherit;
    display: flex;
    align-items: center;
    .en-select{
      /deep/ .el-input__inner{
        border-color: $field-border-color;
      }
      /deep/ .el-input__inner:hover{
        border-color: $field-border-color-hover;
      }
      /deep/ .el-input__inner:focus{
        border-color: $field-border-color-focus;
      }
    }
    .en-select:nth-child(n+1){
      flex: 1;
      margin-left: 10px;
      min-width: 80px;
    }
    .detail-input{
      flex: 3;
      margin-left: 10px;
      min-width: 42%;
      & /deep/ .el-input__suffix{
        line-height: 38px;
      }
      // & /deep/ .el-input__inner {
      //   border-left: none !important;
      //   border-radius: 0;
      // }
    }
  }
  .el-input,
  .en-input-number,
  .el-autocomplete {
    width: 100%;
    /*& /deep/ .el-input__inner {*/
    /*color: inherit;*/
    /*}*/
    .interface {
      cursor: pointer;
      margin-top: 8px;
    }
    & /deep/ .el-input-group__append {
      padding-left: 10px;
      padding-right: 10px;
    }

    & /deep/ .el-input__suffix-inner{
      font-size: 12px;
      color: #a9b5c6;
      margin-right: 6px;
    }
  }
  .address-view{
    .el-input{
      width: 100%;
      & /deep/ .el-input__inner{
        border: none;
        background: #f5f7fa;
        color: #636c78;
      }
    }
  }
}
</style>
<style lang="scss">
.vxe-table-box {
  .address-container {
    .address-select {
      .detail-input {
        margin-left: 0;
      }
    }
  }
  .no-pad-rt {
    .address-container {
      .address-select {
        .detail-input {
          padding-right: 32px;
        }
      }
    }
  }
}
.en-address-item-main {
  width: 100%;
  height: 300px;
  overflow-y: auto;
  .active-address-item {
    background: #f5f8fc;
  }
  .en-address-item {
    height: 52px;
    display: flex;
    justify-content: space-between;
    padding: 10px 11px 10px;
    border-bottom: 1px solid #e8ecf2;
    .en-address-basic {
      width: calc(100% - 120px);
      text-align: left;
      >div{
        width: 100%;
        font-size: 12px;
        line-height: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .en-address-name {
        color: #333333;
      }
      .en-address-details {
        color: #b9c4d2;
        margin-top: 6px;
      }
    }
    .en-address-city-info {
      width: 100%;
      text-align: right;
      font-size: 12px;
      color: #333333;
      font-weight: 400;
      line-height: 12px;
      padding: 10px 0;
    }
    &:hover{
      background: #f5f8fc;
    }
  }
  & + .popper__arrow{
    left: 20px;
  }
}
</style>
