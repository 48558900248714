var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        title: "查看地图",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "amap-page-container" },
        [
          _c("el-amap-search-box", {
            staticClass: "search-box",
            attrs: {
              "search-option": _vm.searchOption,
              "on-search-result": _vm.onSearchResult,
            },
          }),
          _c(
            "el-amap",
            {
              ref: "map",
              staticClass: "amap-demo",
              attrs: {
                vid: "amap" + Math.random(),
                "amap-manager": _vm.amapManager,
                center: _vm.center,
                zoom: _vm.zoom,
                plugin: _vm.plugin,
                events: _vm.events,
              },
            },
            [_c("el-amap-marker", { attrs: { position: _vm.center } })],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "en-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }