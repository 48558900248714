<template>
  <div class="select-address">
    <el-popover
      placement="bottom-start"
      width="400"
      v-model="popoverVisible"
      popper-class="address-popper"
      :visible-arrow="false"
      trigger="click"
      :disabled="disabled">
      <div class="address-container">
        <div class="tab-list-wrap">
          <div class="tab-list">
            <span class="tab-item" v-show="tab.visible" @click="changeTab(tab)" v-for="tab in tabList" :key="tab.value" :class="{active: tab.value === activeTab}">
              {{tab.name}}
            </span>
          </div>
        </div>
        <div class="region-contianer">
          <div class="province-list" v-if="activeTab === 'province'">
            <div class="region-item" v-for="province in provinceList" @click="changeProvince(province, true)"
              :key="province.id" :class="{active: addressData.provinceId === province.ec}">{{province.en}}</div>
          </div>
          <div class="city-list" v-if="activeTab === 'city'">
            <div class="region-item" v-for="city in cityList" @click="changeCity(city, true)"
              :key="city.id" :class="{active: addressData.cityId === city.ec}">{{city.en}}</div>
          </div>
          <div class="area-list" v-if="activeTab === 'area'">
            <div class="region-item" v-for="area in areaList" @click="changeArea(area, true)"
              :key="area.id" :class="{active: addressData.districtId === area.ec}">{{area.en}}</div>
          </div>
        </div>
      </div>
      <div slot="reference" class="abc" >
          <div class="box"></div>
          <el-input  class="address-input address-test" :class="{'disabled-address-input': disabled}" disabled
          :placeholder="placeholder" @keyup.native="handleKeyup"  v-model="showText">
          <en-icon
            slot="append"
            v-if="column.addrRange > 1"
            style="position: relative; top: 3px; cursor: pointer;"
            title="地图"
            color="#3e90fe"
            @click.native.stop="openAmap"
            name="icondituchaxun-dizhikongjian"
            size="small"
          ></en-icon>
          <i
            class="el-icon-close el-input__icon"
            slot="suffix"
            style="cursor: pointer;"
            v-else-if="clearable && showText && !disabled"
            @click.stop="handleIconClick">
          </i>
        </el-input>
      </div>

    </el-popover>
  </div>
</template>

<script>
import cityData from "cityData";

function scrollIntoView(container, selected) {
  if (!selected) {
    container.scrollTop = 0;
    return;
  }
  selected.scrollIntoView();
  // const offsetParents = [];
  // let pointer = selected.parentNode;
  // while (pointer && container !== pointer && container.contains(pointer)) {
  //   offsetParents.push(pointer);
  //   pointer = pointer.offsetParent;
  // }
  // const top = selected.offsetTop + offsetParents.reduce((prev, curr) => (prev + curr.offsetTop), 0);
  // const bottom = top + selected.offsetHeight;
  // const viewRectTop = container.scrollTop;
  // const viewRectBottom = viewRectTop + container.clientHeight;

  // if (top < viewRectTop) {
  //   container.scrollTop = top;
  // } else if (bottom > viewRectBottom) {
  //   container.scrollTop = bottom - container.clientHeight;
  // }
}
const originAddressData = {
  provinceName: "",
  provinceId: "",
  cityName: "",
  cityId: "",
  districtName: "",
  districtId: "",
  address: ""
};
// addrRange 省==4 省市==3 省市区==2 省市区详细地址==1
export default {
  name: "SelectAddress",
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    column: {
      type: Object
    },
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      addressData: {
        ...originAddressData,
        ...this.value
      },
      activeTab: "province",
      provinceList: cityData,
      popoverVisible: false,
      cityList: [],
      areaList: [],
      activeProvinceIndex: -1,
      activeCityIndex: -1,
      activeAreaIndex: -1
    };
  },
  computed: {
    showText() {
      const { provinceName, cityName, districtName } = this.addressData;
      if (provinceName) {
        return `${provinceName } ${ cityName || "" } ${ districtName || ""}`;
      }
      return "";
    },
    tabList() {
      const { addrRange } = this.column;
      return [
        {
          value: "province",
          name: "省",
          visible: true
        },
        {
          value: "city",
          name: "市",
          visible: addrRange < 4
        },
        {
          value: "area",
          name: "区",
          visible: addrRange < 3
        }
      ];
    },
    placeholder() {
      const { addrRange, name, showPrefix } = this.column;
      return showPrefix !== 0 ? name : (addrRange < 3 ? "请选择省 市 区"
        : addrRange < 4 ? "请选择省 市"
        : "请选择省");
    }
  },
  methods: {
    handleKeyup({ keyCode }) {
      if (!this.popoverVisible) {
        if (keyCode === 13) {
          this.popoverVisible = true;
        }
        return;
      }
      const { activeTab } = this;
      const { addrRange } = this.column;
      this.isKeyDown = true;
      switch (keyCode) {
        // 箭头上
        case 38:
          if (activeTab === "province") {
            this.activeProvinceIndex = this.activeProvinceIndex <= 0 ? this.provinceList.length - 1 : this.activeProvinceIndex - 1;
            this.changeProvince(this.provinceList[this.activeProvinceIndex]);
          }
          if (activeTab === "city") {
            this.activeCityIndex = this.activeCityIndex <= 0 ? this.cityList.length - 1 : this.activeCityIndex - 1;
            this.changeCity(this.cityList[this.activeCityIndex]);
          }
          if (activeTab === "area") {
            this.activeAreaIndex = this.activeAreaIndex <= 0 ? this.areaList.length - 1 : this.activeAreaIndex - 1;
            this.changeArea(this.areaList[this.activeAreaIndex]);
          }
          break;
        // 箭头下
        case 40:
           if (activeTab === "province") {
            this.activeProvinceIndex = this.activeProvinceIndex >= this.provinceList.length - 1 || this.activeProvinceIndex === -1 ? 0 : this.activeProvinceIndex + 1;
            this.changeProvince(this.provinceList[this.activeProvinceIndex]);
          }
          if (activeTab === "city") {
            this.activeCityIndex = this.activeCityIndex >= this.cityList.length - 1 ? 0 : this.activeCityIndex + 1;
            this.changeCity(this.cityList[this.activeCityIndex]);
          }
          if (activeTab === "area") {
            this.activeAreaIndex = this.activeAreaIndex >= this.areaList.length - 1 ? 0 : this.activeAreaIndex + 1;
            this.changeArea(this.areaList[this.activeAreaIndex]);
          }
          break;
        // 回车键
        case 13:
          if ((activeTab === "province" && this.activeProvinceIndex === -1) || (activeTab === "city" && this.activeCityIndex === -1)
            || (activeTab === "area" && this.activeAreaIndex === -1)) {
            return;
          }
          if (activeTab === "area" || (activeTab === "city" && addrRange === "3" && this.activeCityIndex !== -1)
            || (activeTab === "province" && addrRange === "4" && this.activeProvinceIndex !== -1)) {
            this.popoverVisible = false;
            return;
          }
          this.activeTab = activeTab === "province" ? "city" : activeTab === "city" ? "area" : "province";
          break;
        // 右键
        case 39:
          if (activeTab === "province" && addrRange < 4 && this.activeProvinceIndex !== -1) {
            this.activeTab = "city";
          }
          if (activeTab === "city" && addrRange < 3 && this.activeCityIndex !== -1) {
            this.activeTab = "area";
          }
          break;
        // 左键
        case 37:
          this.activeTab = activeTab === "area" ? "city" : activeTab === "city" ? "province" : activeTab;
          break;
        default:
          break;
      }
      this.$nextTick(() => {
        this.isKeyDown = false;
        this.scrollIntoView();
      });
    },
    openAmap() {
      this.$emit("openAmap");
    },
    handleIconClick() {
      const { addressData } = this;
      addressData.provinceId = "";
      addressData.provinceName = "";
      addressData.cityId = "";
      addressData.cityName = "";
      addressData.districtId = "";
      addressData.districtName = "";
      this.activeTab = "province";
      this.cityList = [];
      this.areaList = [];
      this.activeProvinceIndex = -1;
      this.activeCityIndex = -1;
      this.activeAreaIndex = -1;
    },
    fillback() {
      const { provinceId, cityId, districtId } = this.value;
      if (provinceId) {
        const province = this.provinceList.find((item) => item.ec === provinceId);
        this.changeProvince(province);
      } else {
        this.addressData.provinceId = "";
        this.addressData.provinceName = "";
      }
      if (cityId) {
        const city = this.cityList.find((item) => item.ec === cityId);
        this.changeCity(city);
      } else {
        this.addressData.cityId = "";
        this.addressData.cityName = "";
      }
      if (districtId) {
        const district = this.areaList.find((item) => item.ec === districtId);
        this.changeArea(district);
      } else {
        this.addressData.districtId = "";
        this.addressData.districtName = "";
      }
    },
    scrollIntoView() {
      this.$nextTick(() => {
        scrollIntoView(document.querySelector(".region-contianer"), document.querySelector(".region-contianer .active"));
      });
    },
    changeTab({ value }) {
      if ((this.areaList.length && value === "area") || (this.cityList.length && value === "city") || value === "province") {
        this.activeTab = value;
        this.scrollIntoView();
      }
    },
    changeProvince({ ns, ec, en }, clearLngAndlat) {
      const { addressData, isKeyDown } = this;
      if (clearLngAndlat) {
        this.value.lng = "";
        this.value.lat = "";
      }
      this.cityList = ns;
      addressData.provinceId = ec;
      addressData.provinceName = en;
      addressData.cityId = "";
      addressData.cityName = "";
      addressData.districtId = "";
      addressData.districtName = "";
      this.scrollIntoView();
      this.activeCityIndex = -1;
      this.areaList = [];
      this.activeProvinceIndex = this.provinceList.findIndex((item) => item.ec === ec);
      // this.changeTab({ value: "province" });
      if (Number(this.column.addrRange) === 4 && !isKeyDown) {
        this.popoverVisible = false;
        return;
      }
      if (!this.isKeyDown) {
        this.activeTab = "city";
      }
    },
    changeCity({ ns, ec, en }, clearLngAndlat) {
      const { addressData, isKeyDown } = this;
      if (clearLngAndlat) {
        this.value.lng = "";
        this.value.lat = "";
      }
      this.areaList = ns;
      addressData.cityId = ec;
      addressData.cityName = en;
      addressData.districtId = "";
      addressData.districtName = "";
      this.scrollIntoView();
      this.activeAreaIndex = -1;
      this.activeCityIndex = this.cityList.findIndex((item) => item.ec === ec);
      if (Number(this.column.addrRange) === 3 && !isKeyDown) {
        this.popoverVisible = false;
        return;
      }
      if (!isKeyDown) {
        this.activeTab = "area";
      }
    },
    changeArea(item, clearLngAndlat) {
      if (clearLngAndlat) {
        this.value.lng = "";
        this.value.lat = "";
      }
      if (!item) {
        return;
      }
      this.addressData.districtName = item.en;
      this.addressData.districtId = item.ec;
      this.activeAreaIndex = this.areaList.findIndex((itm) => itm.ec === item.ec);
      if (!this.isKeyDown) {
        this.popoverVisible = false;
      }
    }
  },
  watch: {
    popoverVisible(val) {
      val && this.scrollIntoView();
    },
    addressData: {
      deep: true,
      handler(newVal) {
        const { address, lat, lng } = this.value;
        this.$emit("input", {
          ...newVal, address, lat, lng
        });
      }
    },
    // eslint-disable-next-line func-names
    "value.provinceId": function() {
      this.fillback();
    },
    // eslint-disable-next-line func-names
    "value.cityId": function() {
      this.fillback();
    },
    // eslint-disable-next-line func-names
    "value.districtId": function() {
      this.fillback();
    }
  },
  mounted() {
    this.fillback();
  }
};

</script>
<style lang="scss" scoped>
.el-input,
.en-input-number,
.el-autocomplete {
  & /deep/ .el-input-group__append {
    padding-left: 10px;
    padding-right: 10px;
  }

  & /deep/ .el-input__suffix-inner{
    font-size: 12px;
    color: #a9b5c6;
    margin-right: 6px;
  }
}
</style>
<style lang="scss" >
.select-address {
  width: 100%;
}
.abc{
  position: relative;
  .box{
    position: absolute;
    width: 100%;
    height: 32px;
    z-index: 101;
    top: 0;
    left: 0;
  }
}
.address-popper[x-placement^=bottom] {
  padding: 0;
  margin-top: 5px;
}
.address-container {
  .tab-list-wrap {
    padding: 0 10px;
    .tab-list {
      display: flex;
      height: 36px;
      border-bottom: 1px solid #f3f3f3;
      .tab-item {
        width: 80px;
        height: 33px;
        line-height: 33px;
        text-align: center;
        font-size: 12px;
        cursor: pointer;
        color: #333;
        &.active {
          color: #179eda;
          &:after {
            content: "";
            border-bottom: 3px solid #179eda;
            display: block;
            width: 100%;
          }
        }
        &:after {
          border-bottom: 3px solid #fff;
        }
      }
    }
  }
  .region-contianer {
    height: 284px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    .region-item {
      height: 32px;
      padding-left: 10px;
      line-height: 32px;
      color: #333333;
      cursor: pointer;
      font-size: 12px;
      &.active {
        color: #179eda;
      }
      &:hover {
        background: #f5f8fc;
      }
    }
  }
  .address-input {
    min-width: 210px;
    .el-icon-close {
      display: none;
    }
    &:hover {
      .el-icon-close {
        display: block;
      }
    }
    .el-input__inner {
      background: #fff !important;
      border: 1px solid #DCDFE6;
      cursor: pointer;
      &:hover {
        border-color: #a2cdf7;
      }
    }
    &.disabled-address-input {
      .el-input__inner {
        background: #f5f7fa !important;
        cursor: auto;
        border: none;
        &:hover {
          border-color: #a2cdf7;
        }
      }
    }
  }
}
</style>
